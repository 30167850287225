import { useState, useEffect } from "react";
import { TablePagination } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { BetItem, SectionHeader, Confirm } from "_components";
import { useFetchWrapper } from '_helpers';
import { betsAtom } from "_state";
import { useBetActions, useAlertActions } from "_actions";
import Pagination from "react-js-pagination";

export { List };

function List({ match }) {
  const { path } = match;
  const fetchWrapper = useFetchWrapper();
  const baseUrl = `${process.env.REACT_APP_API_URL}/bets`;
  const listItem = useRecoilValue(betsAtom);
  const alertActions = useAlertActions();
  const [betsList, setBetsList] = useState([]);
  const betsActions = useBetActions();
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [inside, setInside] = useState(true);


  useEffect(() => {
    betsActions.getAll();
    setTimeout(() => {
      setInside(true)
    }, 1000)
  }, []);


  if (inside && listItem && listItem.length > 0) {
    setBetsList(listItem.slice(0, 10))
    setInside(false);
  }


  const handleChangePage = event => {
    const eventActive = event - 1
    setBetsList(listItem.slice(eventActive * 10, event * 10))
    setPage(event);
  };

  const handleChangeRowsPerPage = event => {
    setRowPerPage(event.target.value);
  };

  const handleDelete = id => {
    setSelectedUser(id);
    setShowModal(true);
  };

  const deleteuser = () => {
    fetchWrapper.delete(`${baseUrl}/${selectedUser}`)
      .then(resp => {
        setShowModal(false);
        alertActions.success("Bet deleted successfully");
        betsActions.getAll()
        setTimeout(() => {
          setInside(true)
        }, 1000)
      })
      .catch(err => {
      });
  };


  const onCloseModal = () => {
    setShowModal(false);
  };


  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <SectionHeader path={path} heading="Pick History" />
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="text-center" style={{ width: "25%" }}>
                  Matchup
                </th>
                <th className="text-center" style={{ width: "20%" }}>
                  Bet Details
                </th>
                <th className="text-center" style={{ width: "20%" }}>
                  Pick and Odds
                </th>
                <th className="text-center" style={{ width: "20%" }}>
                  Game Date
                </th>
                <th className="text-center" style={{ width: "10%" }}>
                  Outcome
                </th>
                <th className="text-center" style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {betsList?.map(bet => (
                <BetItem
                  key={`user-${bet.id}`}
                  data={bet}
                  path={path}
                  onDelete={handleDelete}
                />
              ))}
              {!listItem && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={6}>
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activeClass="active"
                    activePage={page}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={listItem && listItem.length ? listItem.length : 0}
                    pageRangeDisplayed={8}
                    onChange={item => handleChangePage(item)}
                  />
                  {/* <TablePagination
                    component="div"
                    class={{
                      root: "user-pagination"
                    }}
                    count={listItem && listItem.length ? listItem.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={item => handleChangePage(item)}
                    onChangeRowsPerPage={item => handleChangeRowsPerPage(item)}
                  /> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Confirm
        showModal={showModal}
        onClose={onCloseModal}
        onConfirm={deleteuser}
      />
    </div>
  );
}
