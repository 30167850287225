import React from "react";
import { Route, Switch } from "react-router-dom";

import { List, AddEdit } from "./";

import { TopNav, Nav, Alert } from "_components";

export { Users };

function Users({ match, location }) {
  const { path } = match;

  return (
    <>
      <Nav location={location} />
      <div className="inner-container">
        <Alert />
        <div className="w-100">
          <TopNav heading={"Users"} />
          <div className="container-fluid">
            <Switch>
              <Route exact path={path} component={List} />
              <Route path={`${path}/add`} component={AddEdit} />
              <Route path={`${path}/edit/:id`} component={AddEdit} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}
