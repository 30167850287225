import { Link } from "react-router-dom";

import { FaFilter } from "react-icons/fa";

export { SectionHeader };

function SectionHeader({ 
  heading, 
  subLinkTitle, 
  subLink, 
  path,
  buttonContainerClass
}) {
  // only show nav when logged in
  return (
    <div
      className="container-fluid section"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <div className="list-group-horizontal form-row ">
        <h5 className={`font-weight-bold text-black-90 my-0 mr-4 lh-in`}>
          {heading}
        </h5>
        {subLinkTitle ? 
        <Link to={`${path}${subLink}`} className={`btn btn-sm   ${buttonContainerClass || 'btn-dark'}`}>
          {subLinkTitle}
        </Link>
        : null }
      </div>
      <div className="list-group-horizontal form-row action-button">
        <div className="list-group-horizontal form-row  mr-4">
          <img src="img/sort.png" className="action-link" />
          <p>Sort</p>
        </div>
        <div className="list-group-horizontal form-row action-button">
          <FaFilter
            style={{ color: "#C5C7CD", width: 16, height: 16, marginRight: 10 }}
          />
          <p>Filter</p>
        </div>
      </div>
    </div>
  );
}
