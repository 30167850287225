import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { TablePagination } from "@material-ui/core";

import { usersAtom } from "_state";
import { useUserActions } from "_actions";

import { UserItem, SectionHeader, Confirm } from "_components";

export { List };

function List({ match }) {
  const { path } = match;
  const users = useRecoilValue(usersAtom);
  const userActions = useUserActions();
  const [rowsPerPage, setRowPerPage] = useState(2);
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState('')
  const [selectedViewOption, setSelectedViewOption] = useState('')

  useEffect(() => {
    userActions.getAll();
    return userActions.resetUsers;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowPerPage(event.target.value);
  };

  const handleDelete = (id) => {
    setSelectedUser(id);
    setShowModal(true)
  }

  const deleteuser = () => {
    userActions.delete(selectedUser)
    setShowModal(false)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <SectionHeader
            path={path}
            heading="Active Investors"
            subLinkTitle="Create a new user"
            subLink="/add"
            buttonContainerClass={"btn-primary"}s
          />
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>First Name</th>
                <th style={{ width: "20%" }}>Last Name</th>
                <th style={{ width: "15%" }}>Phone</th>
                <th style={{ width: "25%" }}>Email</th>
                <th style={{ width: "10%" }}>View Profile</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {users?.map(user => (
                <UserItem 
                  key={`user-${user.id}`} 
                  user={user} 
                  path={path} 
                  onDelete={handleDelete}
                  setSelectedViewOption={setSelectedViewOption}
                  selectedViewOption={selectedViewOption}
                />
              ))}
              {!users && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={6}>
                  <TablePagination
                    component="div"
                    class={{
                      root: "user-pagination"
                    }}
                    count={users ? users.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={item => handleChangePage(item)}
                    onChangeRowsPerPage={item => handleChangeRowsPerPage(item)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Confirm 
        showModal={showModal} 
        onClose={onCloseModal}
        onConfirm={deleteuser}
      />
    </div>
  );
}
