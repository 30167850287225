import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { authAtom } from "_state";
import { PrivateRoute } from "_components";
import { history } from "_helpers";
import { Home } from "home";
import { Users } from "users";
import { Bet } from "bet";
import { AddEdit } from "bet";
import { Export } from "export";
import { Account } from "account";

export { App };

function App() {
  const auth = useRecoilValue(authAtom);

  return (
    <div className={"app-container" + (auth ? " bg-light" : " login  gray-bg")}>
      <div id="wrapper">
        <Router history={history}>
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute path="/users" component={Users} />
              <PrivateRoute path="/bet" component={Bet} />
              <PrivateRoute path="/editbet" component={AddEdit} />
              <PrivateRoute path="/export" component={Export} />
              <Route path="/account" component={Account} />
              <Redirect from="*" to="/" />
            </Switch>
        </Router>
      </div>
    </div>
  );
}
