import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";

import { betAtom } from "_state";
import { useBetActions, useAlertActions } from "_actions";

export { AddEdit };

function AddEdit({ history, match }) {
  const { id } = match.params;
  const mode = { add: !id, edit: !!id };
  const betActions = useBetActions();
  const alertActions = useAlertActions();
  const bet = useRecoilValue(betAtom);

  // form validation rules
  const validationSchema = Yup.object().shape({
    team1: Yup.string().required("Team 1 is required"),
    team2: Yup.string().required("Team 2 is required"),
    betamount: Yup.string().required("Bet Amount is required"),
    picks: Yup.string().required("Predicted Winner is required"),
    gamedate: Yup.string().required("Match Date is required"),
    outcome: Yup.string().required("Win or Loss is required"),

  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    // fetch user details into recoil state in edit mode
    if (mode.edit) {
      betActions.getById(id);
    }

    return betActions.resetBet;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set default form values after user set in recoil state (in edit mode)
    if (mode.edit && bet) {
      reset(bet);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bet]);

  function onSubmit(data) {
    // history.push("/bet");
    return mode.add ? createUser(data) : updateBet(data.id, data);
  }

  function createUser(data) {
    // return userActions.register(data).then(() => {
    //   history.push("/accounts");
    //   alertActions.success("User added");
    // });
  }

  function updateBet(id, data) {
    return betActions.update(id, data).then(() => {
      history.push("/bet");
      alertActions.success("Bet updated");
    });
  }

  const loading = mode.edit && !bet;
  
  return (
    <>
      <div className="card p-5">
        <div className="card-body px-5 col-xl-4 col-lg-12">
        <div className="form-row pb-5">
        <h5 className="m-0 font-weight-bold text-black-90 pb-1">
          {mode.add ? 'Create A New Bet' : 'Edit Bet'}
        </h5>
        </div>
          {!loading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row py-2">
                <label>Team 1</label>
                <input
                  name="team1"
                  type="text"
                  {...register("team1")}
                  className={`form-control ${
                    errors.team1 ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.team1?.message}
                </div>
              </div>
              <div className="form-row py-2">
                <label>Team 2</label>
                <input
                  name="team2"
                  type="text"
                  {...register("team2")}
                  className={`form-control ${
                    errors.team2 ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.team2?.message}
                </div>
              </div>
              <div className="form-row py-2">
                <label>Bet Amount</label>
                <input
                  name="betamount"
                  type="text"
                  {...register("betamount")}
                  className={`form-control ${errors.betamount ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.betamount?.message}</div>
              </div>
              <div className="form-row py-2">
                <label>Predicted Winner</label>
                <input
                  name="picks"
                  type="text"
                  {...register("picks")}
                  className={`form-control ${errors.picks ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.picks?.message}</div>
              </div>

              <div className="form-row py-2">
                <label>
                  Match Date
                </label>
                <input
                  name="gamedate"
                  type="text"
                  {...register("gamedate")}
                  className={`form-control ${
                    errors.gamedate ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.gamedate?.message}
                </div>
              </div>
              <div className="form-row py-2">
                <label>
                Win or Loss
                </label>
                <input
                  name="outcome"
                  type="text"
                  {...register("outcome")}
                  className={`form-control ${
                    errors.outcome ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.outcome?.message}
                </div>
              </div>
              <div className="form-row pt-3">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary mr-2 w-75"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {mode.add ? 'Create A New User' : 'Update'}
                 </button>
                {/* <button
                  onClick={() => reset(user)}
                  type="button"
                  disabled={isSubmitting}
                  className="btn btn-secondary"
                >
                  Reset
                </button>
                <Link to="/users" className="btn btn-link">
                  Cancel
                </Link> */}
              </div>
            </form>
          )}
          {loading && (
            <div className="text-center p-3">
              <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
