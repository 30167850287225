import { Link } from "react-router-dom";
import { useState } from "react";

import { FaEllipsisV } from "react-icons/fa";
import moment from 'moment';

export { BetItem };

function BetItem({ data, path, onDelete }) {
  const [showAction, setShowAction] = useState(false);

  const deleteUser = () => {
    setShowAction(false);
    onDelete(data.id)
  }

  var date = moment(data.gamedate, 'DD-MM-YYYY')
  const convertedDate = date.format('YYYY-MM-DD');

  return (
    <tr>
      <td className="text-center">
        {`${data.team1} vs ${data.team2}`}
        <span>{`Updated ${moment(convertedDate).fromNow()}`}</span>
      </td>
      <td className="text-center">
        {data.betamount}
        <span>{`${data.spreadamount}`}</span>
      </td>
      <td className="text-center">
        {data.picks}
        <span>{`${data.odds}`}</span>
      </td>
      <td className="text-center">
        {data.gamedate}
        <span>{data.gameTime}</span>
      </td>
      <td className="text-center">
        {data.outcome.toUpperCase() === "WIN" ? (
          <label className="btn btn-sm btn-primary mr-1">{data.outcome}</label>
        ) : (
          <label className="btn btn-sm btn-secondary mr-1">
            {data.outcome}
          </label>
        )}
      </td>
      <td>
      <FaEllipsisV
          color={"#C5C7CD"}
          onClick={() => setShowAction(!showAction)}
        />
        {showAction ? (
          <div className="more-action">
            <ul>
              <li>
                <Link  to={`${path}/edit/${data.id}`} className="">
                  Edit
                </Link>
              </li>
              <li className="pointer" onClick={()=>deleteUser()}>Delete</li>
            </ul>
          </div>
        ) : null}
      </td>
    </tr>
  );
}
