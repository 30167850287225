import { Link } from "react-router-dom";
import { useState } from "react";

import { FaEllipsisV } from "react-icons/fa";

export { HomeBetItem };

function HomeBetItem({ data, onDelete }) {
  const [showAction, setShowAction] = useState(false);

  const deletedata = () => {
    setShowAction(false);
    onDelete(data.id);
  };

  // only show nav when logged in

  return (
    <tr>
      <td className="text-left">
        {data.name}
        <span>{`Updated ${data.updatedOn}`}</span>
      </td>
      <td className="text-left">
        {data.pick}
        <span>{`on ${data.pickedOn}`}</span>
      </td>
      <td className="text-left">
        {data.gameDate}
        <span>{data.gameTime}</span>
      </td>
      <td className="text-left">
        {data.outCome === "win" ? (
          <label className="btn btn-sm btn-primary mr-1">{data.outCome}</label>
        ) : (
          <label className="btn btn-sm btn-secondary mr-1">
            {data.outCome}
          </label>
        )}
      </td>
      <td>
        <FaEllipsisV
          
          
          color={"#C5C7CD"}
          onClick={() => setShowAction(!showAction)}
        />
        {showAction ? (
          <div className="more-action">
            <ul>
              <li className="pointer">
                <Link to={'bet'}className="">Edit</Link>
              </li>
              <li className="pointer" onClick={() => deletedata()}>
                Delete
              </li>
            </ul>
          </div>
        ) : null}
      </td>
    </tr>
  );
}
