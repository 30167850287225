import { Link  } from "react-router-dom";
import { useState, useEffect } from "react";

import { useUserActions } from "_actions";

import { FaEllipsisV } from "react-icons/fa";

export { UserItem };

function UserItem({ user, path, onDelete, selectedViewOption, setSelectedViewOption }) {
  const userActions = useUserActions();
  const [showAction, setShowAction] = useState(false);

  const deleteUser = () => {
    setShowAction(false);
    onDelete(user.id)
  }

  const handelAction = () => {
    setShowAction(!showAction);
    setSelectedViewOption( selectedViewOption ? '' : user.id)
  }

  useEffect(() => {
    setShowAction(selectedViewOption === user.id ? true : false);
  }, [selectedViewOption]);


  // only show nav when logged in
  return (
    <tr key={user.id}>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>1234567890</td>
      <td>{user.email}</td>
      <td style={{ whiteSpace: "nowrap" }}>
        <Link
          to={`${path}/edit/${user.id}`}
          className="btn btn-sm btn-primary mr-1"
        >
          View
        </Link>
        {/* <button onClick={() => userActions.delete(user.id)} className="btn btn-sm btn-danger" style={{ width: '60px' }} disabled={user.isDeleting}>
                                    {user.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span>Delete</span>
                                    }
                                </button> */}
      </td>
      <td>
        <FaEllipsisV
          color={"#C5C7CD"}
          onClick={() => handelAction()}
        />
        {showAction ? (
          <div className="more-action">
            <ul>
              <li className="pointer">
                <Link to={`${path}/edit/${user.id}`} className="">
                  Edit
                </Link>
              </li>
              {/* <li onClick={() => userActions.delete(user.id)}>Delete</li> */}
              <li className="pointer" onClick={() => deleteUser()}>Delete</li>
            </ul>
          </div>
        ) : null}
      </td>
    </tr>
  );
}
