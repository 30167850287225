import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';

import { history, useFetchWrapper } from '_helpers';
import { betsAtom, betAtom } from '_state';

export { useBetActions };

function useBetActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/bets`;
  const fetchWrapper = useFetchWrapper();
  const setBets = useSetRecoilState(betsAtom);
  const setBet = useSetRecoilState(betAtom);


  return {
    getAll,
    getById,
    update,
    resetBet: useResetRecoilState(betAtom)
  }

  function getAll() {
    return fetchWrapper.get(`${baseUrl}`).then(setBets);
  }

  function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`).then(setBet);
  }

  function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
      .then(x => {
        return x;
      });
  }
}