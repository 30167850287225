import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";

import { userAtom } from "_state";
import { useUserActions, useAlertActions } from "_actions";

export { AddEdit };

function AddEdit({ history, match }) {
  const { id } = match.params;
  const mode = { add: !id, edit: !!id };
  const userActions = useUserActions();
  const alertActions = useAlertActions();
  const user = useRecoilValue(userAtom);

  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    password: Yup.string()
      .transform(x => (x === "" ? undefined : x))
      .concat(mode.add ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .transform(x => (x === "" ? undefined : x))
      .concat(mode.add ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters")
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    // fetch user details into recoil state in edit mode
    if (mode.edit) {
      userActions.getById(id);
    }

    return userActions.resetUser;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set default form values after user set in recoil state (in edit mode)
    if (mode.edit && user) {
      reset(user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function onSubmit(data) {
    return mode.add ? createUser(data) : updateUser(user.id, data);
  }

  function createUser(data) {
    return userActions.register(data).then(() => {
      history.push("/accounts");
      alertActions.success("User added");
    });
  }

  function updateUser(id, data) {
    return userActions.update(id, data).then(() => {
      history.push("/accounts");
      alertActions.success("User updated");
    });
  }

  const loading = mode.edit && !user;
  return (
    <>
      
      <div className="card p-5">
        <div className="card-body px-5 col-xl-4 col-lg-12">
        <div className="form-row pb-5">
        <h5 className="m-0 font-weight-bold text-black-90 pb-1">
          {mode.add ? 'Create A New User' : 'Edit User'}
        </h5>
        </div>
          {!loading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row py-2">
                <label>First Name</label>
                <input
                  name="firstName"
                  type="text"
                  {...register("firstName")}
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </div>
              <div className="form-row py-2">
                <label>Last Name</label>
                <input
                  name="lastName"
                  type="text"
                  {...register("lastName")}
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </div>
              <div className="form-row py-2">
                <label>Email</label>
                <input
                  name="email"
                  type="text"
                  {...register("email")}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
              <div className="form-row py-2">
                <label>Phone</label>
                <input
                  name="phone"
                  type="text"
                  {...register("phone")}
                  className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.phone?.message}</div>
              </div>

              <div className="form-row py-2">
                <label>
                  Password
                  {mode.edit && <em className="ml-1"></em>}
                </label>
                <input
                  name="password"
                  type="password"
                  {...register("password")}
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </div>
              <div className="form-row py-2">
                <label>
                  Confirm Password
                  {mode.edit && <em className="ml-1"></em>}
                </label>
                <input
                  name="confirmPassword"
                  type="password"
                  {...register("confirmPassword")}
                  className={`form-control ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.confirmPassword?.message}
                </div>
              </div>
              <div className="form-row pt-3">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary mr-2 w-75"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {mode.add ? 'Create A New User' : 'Update'}
                 </button>
                {/* <button
                  onClick={() => reset(user)}
                  type="button"
                  disabled={isSubmitting}
                  className="btn btn-secondary"
                >
                  Reset
                </button>
                <Link to="/users" className="btn btn-link">
                  Cancel
                </Link> */}
              </div>
            </form>
          )}
          {loading && (
            <div className="text-center p-3">
              <span className="spinner-border spinner-border-lg align-center"></span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
