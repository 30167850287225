import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { alertAtom } from "_state";
import { useAlertActions } from "_actions";
import { history } from "_helpers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export { Confirm };

function Confirm({
  showModal,
  onClose,
  onConfirm
}) {

  return (
    <Dialog open={showModal} fullWidth={true} className="pt-5">
      <DialogTitle className="text-center pt-5" id="alert-dialog-title">
        ARE YOU SURE
      </DialogTitle>

      <DialogActions className="js-center pb-5">
        <button
          className="btn btn-dark-2 w-33 br-0 mr-3"
          onClick={() => onConfirm()}
        >
          YES
        </button>
        <button
          className="btn btn-dark-2 w-33 br-0"
          onClick={() => onClose()}
        >
          NO
        </button>
      </DialogActions>
    </Dialog>
  );
}
