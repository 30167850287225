import React from "react";
import { Route, Switch } from "react-router-dom";

import { List } from "./";

import { TopNav, Nav, Alert } from "_components";

export { Export };

function Export({ match, location }) {
  const { path } = match;

  return (
    <>
      <Nav location={location} />
      <div className="inner-container">
        <Alert />
        <div className="w-100">
          <div className="">
            <TopNav heading={"Picks"} />
            <div className="container-fluid">
              <Switch>
                <Route exact path={path} component={List} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
