import { useState } from "react";
import { TablePagination } from "@material-ui/core";
import { history, useFetchWrapper } from '_helpers';
import { BetItem, SectionHeader } from "_components";

export { List };

const data = [
  {
    heading: "Update Database with Handicapper Weekly Picks",
    subHeading:
      "Place csv file to update the current database with the new data provided by handicappers on weekly game picks",
    button: "Update",
    displayInput: true,
    id: 1,
  },
  {
    heading: "Export Matchups",
    subHeading:
      "Place gameday or weekly matchups into a csv file and upload. Click export to have the algorithm update the matchup predictions into the existing spreadsheet based on existing data.",
    button: "Export and Download",
    displayInput: false,
    id: 2,
  },
  {
    heading: "Posting Bets",
    subHeading: "",
    button: "Post",
    displayInput: true,
    id: 3,
  }
];



function List({ match }) {
  const fetchWrapper = useFetchWrapper();
  const baseUrl = `${process.env.REACT_APP_API_URL}/bets/upload`;
  const [fileValue, setFileValue] = useState('');

  console.log('baseUrl', baseUrl);
  console.log('fileValue', fileValue)

  const handleSave = item => {
    if (item.id === 1 && fileValue) {
      let data = new FormData();
      data.append("file", fileValue);
      return fetchWrapper.post(`${baseUrl}`, fileValue)
        .then(resp => {
          setFileValue('');
          console.log('resp', resp)
        })
        .catch(err => {
          console.log('err', err)
        });
    }
  };

  const onFileChange = value => {
    const image = value.target.files[0];
    // setFileValue(image);
    console.log('image', image)
    console.log('value', value.target.value);
    setFileValue(value.target.value)
  };


  return (
    <div className="">
      <div className="col-xl-12">
        <div className="card export">
          <div className="card-body px-5">
            <div className="row px-5 pt-3">
              {data.map(item => (
                <div className="col-xl-7 col-lg-12 fl-column py-5">
                  <h5 className="m-0 font-weight-bold text-black-90 pb-1">
                    {item.heading}
                  </h5>
                  {item.subHeading ? (
                    <span className="fs-75 gray-text pb-1">
                      {item.subHeading}
                    </span>
                  ) : null}
                  <div className="pt-3">
                    <div className="btn btn-dark w-66 p-1" onClick={() => handleSave(item)} >{item.button}</div>
                    {item.displayInput ? (
                      <>
                        <input type="file" id="img1" hidden onChange={onFileChange} />
                        <label className="file-label px-3 ml-5" for="img1">
                          Place csv. file here
                        </label>
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
              ;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
