import React, { useState } from "react";

import { useRecoilValue } from "recoil";

import { authAtom } from "_state";

import { TopNav, HomeBetItem, Confirm, Nav, Alert } from "_components";

import queryString from "query-string";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";

export { Home };

const data = [
  {
    name: "Raiders vs Cowboys",
    pick: "Raiders",
    pickedOn: "24.05.2021",
    updatedOn: "1 day ago",
    gameDate: "May 26, 2021",
    gameTime: "6:30 AM",
    outCome: "win"
  },
  {
    name: "Lakers vs Clippers",
    pick: "Lakers",
    pickedOn: "24.05.2021",
    updatedOn: "1 day ago",
    gameDate: "May 26, 2021",
    gameTime: "8:00 AM",
    outCome: "loss"
  }
];

function Home({ location }) {
  const auth = useRecoilValue(authAtom);
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const parsed = queryString.parse(location);
  console.log("location", location);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "end"
      },
      title: {
        display: false,
        text: "Chart.js Line Chart"
      }
    }
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Today",
        data: labels.map(() => Math.floor(Math.random() * 100 + 1)),
        borderColor: "#3751FF",
        borderWidth: 2,
        backgroundColor: "#3751FF",
        tension: 0.5
      },
      {
        label: "Yesterday",
        data: labels.map(() => Math.floor(Math.random() * 100 + 1)),
        borderColor: "#DFE0EB",
        borderWidth: 2,
        backgroundColor: "#DFE0EB",
        tension: 0.5
      }
    ]
  };

  const handleDelete = id => {
    setSelectedUser(id);
    setShowModal(true);
  };

  const deleteuser = () => {
    //userActions.delete(selectedUser)
    setShowModal(false);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  // only show nav when logged in
  if (!auth) return null;

  return (
    <>
      <Nav location={location} />
      <div className="inner-container">
        <Alert />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopNav heading={"Admin Overview"} />

            <div className="container-fluid">
              {/* Page Heading */}
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
<h1 className="h3 mb-0 text-gray-800">Admin Overview</h1>
</div> */}

              {/* Content Row */}
              <div className="row">
                {/* Informational Cards */}
                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card text-center  h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-lg font-weight-bold  mb-1">
                            Games Bet Today
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-black-90 text-vlg">
                            100
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4 ">
                  <div className="card text-center  h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-lg font-weight-bold  mb-1">
                            Todays Bet Total
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-black-90 text-vlg">
                            $27,000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4 border">
                  <div className="card text-center shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-lg font-weight-bold  mb-1">
                            Todays Bets Won
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-black-90 text-vlg">
                            57
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 border">
                  <div className="card text-center shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-lg font-weight-bold  mb-1">
                            Todays Bets Lost
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-black-90 text-vlg">
                            43
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Todays Bet Total</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">$27,000</div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                {/* </div><div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Bets Won
                                </div>
                                <div className="row no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">57</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                    Bets Lost</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">43</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         */}
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card shadow mb-4 list-group-horizontal form-row">
                    <div className="col-xl-8 col-lg-12">
                      <div className="card-header">
                        <h5 className="m-0 font-weight-bold text-black-90">
                          Fund Balance Overview
                        </h5>
                        <span>as of 25 November 2021 at 9:15 PM</span>
                      </div>
                      <div className="pb-4 pl-4">
                        {/* <Chart data={chartData} axes={axes} /> */}
                        <Line options={options} data={chartData} />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                      <div className="chart-content">
                        <ul>
                          <li>
                            Fund Total Balance
                            <span>$1,925,438</span>
                          </li>
                          <li>
                            Fund Return %<span>92%</span>
                          </li>
                          <li>
                            Total Games Bet
                            <span>2654</span>
                          </li>
                          <li>
                            Total Won
                            <span>1327</span>
                          </li>
                          <li>
                            Total Lost
                            <span>1327</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-4 col-lg-5">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Revenue Sources
                  </h6>
                  <div className="dropdown no-arrow">
                    <a
                      className="dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <div className="dropdown-header">Dropdown Header:</div>
                      <a className="dropdown-item">Action</a>
                      <a className="dropdown-item">Another action</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="chart-pie pt-4 pb-2">
                    <canvas id="myPieChart"></canvas>
                  </div>
                  <div className="mt-4 text-center small">
                    <span className="mr-2">
                      <i className="fas fa-circle text-primary"></i> Direct
                    </span>
                    <span className="mr-2">
                      <i className="fas fa-circle text-success"></i> Social
                    </span>
                    <span className="mr-2">
                      <i className="fas fa-circle text-info"></i> Referral
                    </span>
                  </div>
                </div>
              </div>
            </div>
           */}
              </div>

              <div className="row">
                <div className="col-lg-12 mb-12">
                  <div className="card shadow mb-4">
                    <div className="card-header">
                      <h5 className="m-0 font-weight-bold text-black-90">
                        Pick History
                      </h5>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ width: "35%" }}>
                            Matchup
                          </th>
                          <th className="text-left" style={{ width: "25%" }}>
                            Pick
                          </th>
                          <th className="text-left" style={{ width: "20%" }}>
                            Game Date
                          </th>
                          <th className="text-left" style={{ width: "10%" }}>
                            Outcome
                          </th>
                          <th
                            className="text-left"
                            style={{ width: "10%" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map(user => (
                          <HomeBetItem
                            key={`user-${user.id}`}
                            data={user}
                            onDelete={handleDelete}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="row">

            
            <div className="col-lg-6 mb-4">

                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Projects</h6>
                    </div>
                    <div className="card-body">
                        <h4 className="small font-weight-bold">Server Migration <span
                                className="float-right">20%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar bg-danger" role="progressbar" sstyle={{width: 20}}
                                aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Sales Tracking <span
                                className="float-right">40%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar bg-warning" role="progressbar" style={{width: 40}}
                                aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Customer Database <span
                                className="float-right">60%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar" role="progressbar" style={{width: 60}}
                                aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Payout Details <span
                                className="float-right">80%</span></h4>
                        <div className="progress mb-4">
                            <div className="progress-bar bg-info" role="progressbar" style={{width: 80}}
                                aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="small font-weight-bold">Account Setup <span
                                className="float-right">Complete!</span></h4>
                        <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: 100}}
                                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>

                
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-primary text-white shadow">
                            <div className="card-body">
                                Primary
                                <div className="text-white-50 small">#4e73df</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-success text-white shadow">
                            <div className="card-body">
                                Success
                                <div className="text-white-50 small">#1cc88a</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-info text-white shadow">
                            <div className="card-body">
                                Info
                                <div className="text-white-50 small">#36b9cc</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-warning text-white shadow">
                            <div className="card-body">
                                Warning
                                <div className="text-white-50 small">#f6c23e</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-danger text-white shadow">
                            <div className="card-body">
                                Danger
                                <div className="text-white-50 small">#e74a3b</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-secondary text-white shadow">
                            <div className="card-body">
                                Secondary
                                <div className="text-white-50 small">#858796</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-light text-black shadow">
                            <div className="card-body">
                                Light
                                <div className="text-black-50 small">#f8f9fc</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className="card bg-dark text-white shadow">
                            <div className="card-body">
                                Dark
                                <div className="text-white-50 small">#5a5c69</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-lg-6 mb-4">

                
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Illustrations</h6>
                    </div>
                    <div className="card-body">
                        <div className="text-center">
                            
                        </div>
                        <p>Add some quality, svg illustrations to your project courtesy of <a
                                target="_blank" rel="nofollow" href="https://undraw.co/">unDraw</a>, a
                            constantly updated collection of beautiful svg images that you can use
                            completely free and without attribution!</p>
                        <a target="_blank" rel="nofollow" href="https://undraw.co/">Browse Illustrations on
                            unDraw &rarr;</a>
                    </div>
                </div>

               
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Development Approach</h6>
                    </div>
                    <div className="card-body">
                        <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classNamees in order to reduce
                            CSS bloat and poor page performance. Custom CSS classNamees are used to create
                            custom components and custom utility classNamees.</p>
                        <p className="mb-0">Before working with this theme, you should become familiar with the
                            Bootstrap framework, especially the utility classes.</p>
                    </div>
                </div>

            </div>
        </div> */}
            </div>
          </div>
          <Confirm
            showModal={showModal}
            onClose={onCloseModal}
            onConfirm={deleteuser}
          />
        </div>
      </div>
    </>
  );
}
