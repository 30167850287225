import { useState } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useUserActions } from '_actions';

import { FaEyeSlash, FaEye } from "react-icons/fa";

export { Login };

function Login() {
    const userActions = useUserActions();
    const [showPassword, setShowPassword] = useState(false)

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    return (
        <div className="card m-3">
            <div className="text-center">
              <img src="/img/black-logo.png" className="blogo" />
            </div>
            <h4 className="text-center">Enter your email and password below</h4>
            <div className="card-body account">
                <form onSubmit={handleSubmit(userActions.login)}>
                    <div className="form-group">
                        <label>Email</label>
                        <input name="email" placeholder="Email address" type="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                    <div className="form-group">
                        <div className="fl-row justify-content-sm-between">
                          <label>Password</label>
                          <span>Forgot password?</span>
                        </div>
                        <input name="password" placeholder="Password" type={showPassword ? "text" : "password"} {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                        <div className="input-icon" onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <FaEyeSlash size={20} /> : <FaEye  size={20}/>}
                        </div>
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                    <button disabled={isSubmitting} className="btn btn-dark">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Login
                    </button>
                    <Link to="register" className="btn btn-link">Register</Link>
                </form>
            </div>
        </div>
    )
}
