import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { FaChartPie, FaLightbulb, FaUsers, FaTicketAlt } from "react-icons/fa";
import { MdSettings } from "react-icons/md";

import { authAtom } from "_state";
import { useUserActions } from "_actions";

import queryString from 'query-string';


export { Nav };

function Nav({location}) {
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  
  // only show nav when logged in
  if (!auth) return null;
  //const parsed = queryString.parse(location.search);
  let urlPath = '';
  if(location && location.pathname) {
    const path = location.pathname.split('/');
    console.log('path', path)
    urlPath = path[1]
  }
  console.log('urlPath', urlPath)
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <NavLink
        exact
        to="/"
        className="sidebar-brand d-flex align-items-center justify-content-center"
      >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink"></i>
        </div>
        {/* <div className="sidebar-brand-text mx-3">Sportiva <sup>1</sup></div> */}
        <div className="sidebar-brand-text mx-3">
          <img src="img/logo.png" />
        </div>
      </NavLink>

      <li className={`nav-item ${urlPath === '' ? 'active' : ''}`}>
        <NavLink exact to="/" className="nav-link">
          <FaChartPie />
          <span>Fund Overview</span>
        </NavLink>
      </li>

      <li className={`nav-item ${urlPath === 'export' ? 'active' : ''}`}>
        <NavLink exact to="/export" className={`nav-link`}>
          <FaTicketAlt />
          <span>Data Import/Export</span>
        </NavLink>
      </li>

      <li className={`nav-item ${urlPath === 'bet' ? 'active' : ''}`}>
        <NavLink exact to="/bet" className="nav-link">
          <FaLightbulb />
          <span>Bets and Picks</span>
        </NavLink>
      </li>

      <li className={`nav-item ${urlPath === 'users' ? 'active' : ''}`}>
        <NavLink exact to="/users" className="nav-link">
          <FaUsers />
          <span>Users</span>
        </NavLink>
      </li>

      <li className="nav-item ">
        <NavLink exact to="/" className="nav-link">
          <MdSettings />
          <span>Settings</span>
        </NavLink>
      </li>

      <li className="nav-item ">
        <a onClick={userActions.logout} className="nav-item nav-link">
          Logout
        </a>
      </li>

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  );
}
