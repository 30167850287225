import { atom } from 'recoil';

const betsAtom = atom({
    key: 'bets',
    default: null
});

const betAtom = atom({
  key: 'bet',
  default: null
});

export { 
  betsAtom,
  betAtom
};